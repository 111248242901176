<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'
import type { PurchaseOrder } from '/~/types/api/order'
import { formatDate } from '/~/utils/format/date'
import { getCashbackRebate } from '/~/utils/rebates'
import BaseButton from '/~/components/base/button/base-button'
import BaseLink from '/~/components/base/link/base-link.vue'
import BaseLoader from '/~/components/base/loader/base-loader.vue'
import CashbackPanel from '/~/components/cashback/cashback-panel.vue'
import CheckoutSummaryAddress from '/~/components/checkout/summary/checkout-summary-address.vue'
import CheckoutSummaryItems from '/~/components/checkout/summary/checkout-summary-items.vue'
import CheckoutSummaryPayWith from '/~/components/checkout/summary/checkout-summary-pay-with.vue'
import CheckoutConfirmationStatus from '/~/components/checkout/summary/checkout-summary-status.vue'
import CheckoutSummaryTotal from '/~/components/checkout/summary/checkout-summary-total.vue'
import PointsPanel from '/~/components/points/points-panel.vue'
import { useActivity } from '/~/composables/activity'
import { useCms } from '/~/composables/cms'
import { PaymentMethodType } from '/~/composables/payment-methods/payment-methods-types'
import { usePoints } from '/~/composables/points'
import { useProvider } from '/~/composables/provider'

const props = defineProps<{ orderId: string }>()

const { isUserAddressEnabled } = useProvider()
const { getPurchaseOrderItem } = useActivity()
const { isPurchaseOrderPoints, calculatePointsEarnedForPurchase } = usePoints()
const { ewalletLabels } = useCms()

const isReady = ref(false)
const order = ref<PurchaseOrder | undefined>(undefined)
const failed = ref(false)

const ePurchasesRoute = { hash: '#profile-e-purchases' }

const status = computed(() => (order.value?.status ?? '').toLowerCase())
const financialStatus = computed(() =>
  (order.value?.financialStatus ?? '').toLowerCase()
)
const isCompleted = computed(() => {
  return (
    financialStatus.value === 'paid' &&
    ['processing', 'completed'].includes(status.value)
  )
})
const paid = computed(() => financialStatus.value === 'paid')
const orderItems = computed(() => {
  return order.value?.items ?? []
})
const orderFilteredItems = computed(() =>
  orderItems.value.filter((i) => i.type !== 'coupon')
)
const eStoreItems = computed(() => order.value?.e_store_items ?? [])
const hasPhysical = computed(() =>
  orderFilteredItems.value.some(
    (item) => item.physical && item.type !== 'estore'
  )
)
const hasDigital = computed(() =>
  orderFilteredItems.value.some((item) => !item.physical)
)
const singleDigital = computed(() => {
  if (orderFilteredItems.value.length === 1) {
    const item = orderFilteredItems.value[0]

    return !item.physical ? item : false
  }
  return false
})
const hasEStore = computed(
  () =>
    eStoreItems.value.length > 0 ||
    orderFilteredItems.value.some((item) => item.type === 'estore')
)
const hasCinema = computed(() =>
  orderFilteredItems.value.some((item) => item.type === 'voucher')
)
const hasBankAccountMethod = computed(() => Boolean(bankAccount.value))
const isGift = computed(
  () => orderFilteredItems.value?.[0]?.gift && singleDigital.value
)
const items = computed(() => {
  const updatedOrderItems = orderFilteredItems.value.map((item) => {
    if (!item.type) {
      item.type = 'retailer'
    }
    return item
  })

  const updatedEStoreItems = eStoreItems.value.map((item) => {
    item.type = 'product'
    return item
  })

  return [...updatedOrderItems, ...updatedEStoreItems]
})
const fees = computed(() => order.value?.fees?.items ?? [])
const transactionFee = computed(() => {
  const fee = fees.value.find((item) => item.label === 'Processing Fee')

  return fee ? Number(fee.amount) : 0
})
const shippingFee = computed(() => {
  const fee = fees.value.find((item) => item.label === 'Shipping Fee')

  return fee ? Number(fee.amount) : 0
})
const coupons = computed(() => {
  const items =
    order.value?.paymentMethods.filter(
      (i) => i?.type === PaymentMethodType.couponCode
    ) ?? []
  const total = items.reduce((acc, i) => acc - Number(i.amount), 0)

  return { items, total }
})
const creditCard = computed(() => {
  const card = order.value?.paymentMethods.find(
    (i) => i.type === PaymentMethodType.creditCard
  )

  if (!card) {
    return null
  }

  const details = {
    name: 'Credit Card',
    number: card.number,
  }

  return {
    details,
    total: Number(card.finalAmount ?? card.amount),
  }
})
const points = computed(() => {
  const pointsMethod = order.value?.paymentMethods.find(
    (i) => i.type === PaymentMethodType.points
  )

  if (!pointsMethod) {
    return null
  }

  const details = {
    usePoints: pointsMethod.points,
  }

  return {
    details,
    total: Number(pointsMethod.amount),
  }
})
const ewallet = computed(() => {
  const ewalletMethod = order.value?.paymentMethods.find(
    (i) => i.type === PaymentMethodType.eWallet
  )

  if (!ewalletMethod) {
    return null
  }

  const details = {
    // usePoints: 0,
  }

  return {
    details,
    total: Number(ewalletMethod.amount),
  }
})
const bankAccount = computed(() => {
  const bankAccountMethod = order.value?.paymentMethods.find(
    (i) => i.type === PaymentMethodType.bankAccount
  )

  if (!bankAccountMethod) {
    return null
  }

  const details = {
    accountName: 'Bank Account',
    accountNumber: bankAccountMethod.accountNumber || bankAccountMethod.number,
    bsb: bankAccountMethod.bsb || bankAccountMethod.prefix,
  }

  return {
    details,
    total: Number(bankAccountMethod.finalAmount ?? bankAccountMethod.amount),
  }
})
const pointsEarned = computed(() => {
  const pointsTransactions = order.value?.pointsTransactions || []
  const earnTransaction = pointsTransactions.filter((t) => t.type === 'earn')

  if (earnTransaction.length > 0) {
    return earnTransaction[0].allocatedValue
  }

  return calculatePointsEarnedForPurchase(order.value?.items ?? [])
})
const cashbackRebateTotal = computed(() =>
  getCashbackRebate(order.value?.rebateTotalByType ?? [])
)

onMounted(async () => {
  try {
    const result = await getPurchaseOrderItem(props.orderId)

    order.value = result
  } catch (e) {
    console.error(e)
    failed.value = true
  } finally {
    isReady.value = true
  }
})
</script>

<template>
  <div class="h-full w-full sm:px-6">
    <div
      class="flex min-h-full w-full bg-eonx-neutral-50 sm:rounded-t-3xl sm:pt-[30px]"
    >
      <div
        class="container relative mx-auto flex min-h-full w-full max-w-lg flex-col"
      >
        <div class="h-full bg-white px-5 pt-10 sm:rounded-t-3xl sm:px-10">
          <base-loader v-if="!isReady" fullwidth />

          <div
            v-else-if="failed"
            class="py-[30px] text-center text-2xl font-bold"
          >
            Order information not found

            <div class="mt-[30px]">
              <base-button full-width @click="$router.push({ name: 'home' })">
                Back to Dashboard
              </base-button>
            </div>
          </div>

          <div v-else>
            <checkout-confirmation-status :order="order" />

            <div class="mb-6 text-center">
              <div class="font-bold leading-relaxed text-eonx-neutral-600">
                Order No. #{{ orderId }}
              </div>
              <div
                class="mt-[5px] font-bold leading-relaxed text-eonx-neutral-600"
              >
                {{ formatDate('daymonthyearlongtime', order?.placedAt) }}
              </div>
            </div>

            <div class="mb-6 space-y-6">
              <p v-if="hasDigital" class="w-full text-center text-sm leading-6">
                <template v-if="hasBankAccountMethod">
                  By selecting your bank account as the payment method, your
                  order will not be fulfilled until the funds have cleared in
                  our system.
                  <b>This can take up to 3 business days.</b>
                </template>
                <template v-else>
                  ePurchases will be delivered to your email and available in
                  {{ ewalletLabels.yourEwallet }}
                  instantly, however on occasion this may take up to 72 hours.
                </template>
              </p>

              <p
                v-if="hasPhysical"
                class="w-full text-center text-sm leading-6"
              >
                Physical Gift Cards will be mailed to your delivery address
                within 5 business days.
              </p>

              <p v-if="hasEStore" class="w-full text-center text-sm leading-6">
                eStore Products will be sent to your delivery address within 5
                to 15 business days subject to stock levels. If you have any
                questions about your order,
                <base-link :to="{ name: 'help' }" class="font-bold">
                  contact us
                </base-link>
                .
              </p>
            </div>

            <div class="mb-2.5 text-xl font-bold text-eonx-neutral-800">
              Summary
            </div>
            <div class="divide-y">
              <checkout-summary-address
                v-if="isUserAddressEnabled"
                :billing="order?.address"
                :loading="!isReady"
                class="pb-5"
              />
              <checkout-summary-items :items="items" class="py-5" />
              <checkout-summary-pay-with
                class="pt-5"
                title="Paid with"
                :credit-card="creditCard"
                :points="points"
                :ewallet="ewallet"
                :bank-account="bankAccount"
                :coupons="coupons"
              />
              <checkout-summary-total
                :transaction-fees="transactionFee"
                :shipping-fees="shippingFee"
                :amount="Number(order?.subtotal)"
                :total="Number(order?.total)"
                class="pt-5"
              />
            </div>

            <points-panel
              v-if="isPurchaseOrderPoints"
              :total="pointsEarned"
              :completed="isCompleted"
              :loading="!isReady"
              class="-mx-5 mt-5 sm:-mx-10"
            />
            <cashback-panel
              :total="cashbackRebateTotal"
              :completed="isCompleted"
              :loading="!isReady"
              class="-mx-5 mt-5 sm:-mx-10"
            />
            <div class="sticky bottom-0 -mx-5 bg-white px-10 py-6 sm:-mx-10">
              <div class="flex w-full space-x-6">
                <base-button
                  v-if="paid && hasDigital && !isGift"
                  class="flex-1"
                  look="outlined-color"
                  @click="$router.push(ePurchasesRoute)"
                >
                  <template v-if="singleDigital">
                    <template v-if="hasCinema">View eVoucher</template>
                    <template v-else>View eGift Card</template>
                  </template>
                  <template v-else>View ePurchases</template>
                </base-button>

                <base-button
                  class="flex-1"
                  @click="$router.push({ name: $route.meta.home })"
                >
                  Done
                </base-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
